/* *********************** Custom Color *********************** */

:root {
    --primary-color: #cccccc;
    --secondary-color: #cccccc;
    --tertiary-color: #cccccc;
    --hover-color: rgba(220, 35, 17, 0.2);
}


/* ************************************************************ */

.notDisponibile{
    background: url('../images/non-disponibile.png');
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.onlyShopping{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-alpha-color);
    height: 55px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    padding: 0px 10px;
}


.slider {
    width: 95% !important;
    margin: auto;
}

.marker {
    cursor: pointer;
}

#bottomSideBar{
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--primary-color) !important;
    z-index: 100;
}

#bottomSideBar ul{
    justify-content: center;
}

#bottomSideBar ul li, #bottomSideBar .width20{
    width: 20%;
    text-align: center;
}

#bottomSideBar ul li a.active{
    background-color: var(--secondary-color) !important;
}

#bottomSideBar a{
    color: white;
}

#bottomSideBar a i{
    font-size: 20px;
    line-height: 24px;
}

#bottomSideBar a p{
    display: none;
}

.mini-drawer-menu-icon {
    color: var(--primary-color) !important;
}

.sidebar {
    background-color: var(--primary-color) !important;
}

.right-sidebar {
    background-color: var(--primary-color) !important;
}

.right-sidebar-hint-icon>svg {
    color: var(--secondary-color) !important;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid var(--secondary-color) !important;
}

.nav-link .main-list .active {
    background-color: var(--secondary-color) !important;
}

.btn-primary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: #ffffff !important;
}

.btn-secondary {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    color: #ffffff !important;
}

.customProduct:hover {
    background-color: var(--hover-color);
}

.customLink:hover {
    background-color: var(--hover-color);
}

.slider-control-bottomcenter {
    bottom: -40px !important;
}

.carousel-arrow-right {
    position: absolute;
    right: 0;
}

.carousel-arrow-left {
    position: absolute;
    left: -0.5rem;
}

.carousel-arrow-right:hover {
    cursor: pointer;
}

.carousel-arrow-left:hover {
    cursor: pointer;
}

.carousel-pagination-button {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.carousel-pagination-button:not(:disabled):not(.disabled).active {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
}

#modalCarousel .carousel-root .carousel:nth-child(2){
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    width: 80px;
    display: none;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover{
    background: var(--primary-color) !important;
}

.carousel.carousel-slider .control-arrow{
    background: var(--primary-color) !important;
    background: white !important;
    opacity: 1 !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid var(--primary-color) !important;
    }
    
    .carousel .control-prev.control-arrow:before {
    border-right: 8px solid var(--primary-color) !important;
    }

.carousel .control-dots .dot{
    background: var(--secondary-color) !important;
    opacity: 1;
}

.carousel.carousel-slider{
    overflow: visible;
}

.carousel .control-dots{
    bottom: -50px;
    padding-inline-start: 0;
}

#modalCarousel .carousel-root .carousel:nth-child(2)::-webkit-scrollbar {
   /* display: none; */
}

#modalCarousel .carousel-root{
  /*  position: relative; */
}

#modalCarousel{
    position: relative;
    overflow: hidden;
    flex-basis: auto;
}

#modalCarousel .carousel .thumbs-wrapper{
    margin: 0;
}

#modalCarousel .carousel .thumbs{
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 0px;
    transform: translate3d(0, 0, 0) !important;
}

#modalCarousel .carousel .thumbs .thumb{
    display: block;
    border: 0 !important;
    opacity: 0.4;
    height: 73px;
}

#modalCarousel .carousel .thumbs .thumb{
    max-height: 73px;
}

#modalCarousel .carousel .thumbs .thumb.selected{
    border: 0 !important;
    opacity: 0.9;
}

.aggiungiButtonText{
    color: var(--primary-color) !important;
    font-weight: bold;
    font-size: 13px;
}


/* Icon marker */

.icon-marker {
    width: 22px;
}

.marker[type="plus"] .icon-marker {
    width: 18px;
}

.icon-marker-promo {
    width: 25px;
}

#indiceId .btn-group{
    display: inline-block;
}

/*Carousel modals markers*/

.carousel .slide img {
    width: auto !important;
}

@media (max-width: 767px) {
    .carousel .slide img {
        width: auto !important;
    }

    #modalCarousel .carousel .thumbs .thumb{
        max-height: 55px;
    }

    .mini-drawer-menu-icon .fa-bars, #mobile-search-hidden{
        display: none;
    }

    #fullScreenId{
        display: none !important;
    }
}

.carousel .slide {
    background-color: white !important;
}

.carousel .thumb img {
    max-height: 50px !important;
    width: auto !important;
}

.carousel .thumb {
    text-align: center !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    /* border: 2px solid var(--primary-color) !important; */
    border: 3px solid var(--primary-color) !important;
}

.carousel .slider {
    width: 100% !important;
}


/*Right Sidebar*/

.right-sidebar-header {
    cursor: pointer;
    position: relative;
    z-index: 4;
    padding: 15px;
}

.right-sidebar-img-logo {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.right-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 80px;
    display: block;
    z-index: 3;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    overflow-x: hidden;
    background-position: center center;
}

.right-sidebar .right-sidebar-wrapper {
    position: relative;
    height: calc(100vh - 80px);
    z-index: 4;
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
}

#h5total {
    line-height: 37px;
}

.right-sidebar .nav li {
    width: 100%;
}

.right-sidebar .nav li .card .card-body a {
    color: #ffffff;
    opacity: 0.86;
    border-radius: 4px;
    margin: 5px 10px 0;
}

.right-sidebar .nav li>a {
    color: #ffffff;
    opacity: 0.86;
    border-radius: 4px;
    margin: 5px 3px 0;
    padding: 0.5rem 0.7rem !important;
}

.right-sidebar .nav .caret {
    top: 24px;
    position: absolute;
    right: 15px;
}

.right-sidebar .nav .span-shortname {
    font-size: 12px;
    float: left;
    margin-right: 15px;
    line-height: 30px;
    width: 30px;
    text-align: center;
}

.right-sidebar .nav i {
    font-size: 25px;
    float: left;
    margin-right: 15px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    border: 1px #ffffff solid;
    border-radius: 50%;
}

.right-sidebar .nav img {
    font-size: 25px;
    margin-right: 15px;
    line-height: 40px;
    width: 50px;
    text-align: center;
}

.right-sidebar:after,
.right-sidebar:before,
body>.navbar-collapse:after,
body>.navbar-collapse:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

#desktop-grocery {
    width: 40px;
}

.custom-grocery-notification-badge {
    background-color: var(--secondary-color) !important;
    top: -53px !important;
    right: -15px !important;
}

.custom-mobile-grocery-notification-badge {
    background-color: var(--secondary-color) !important;
    top: -23px !important;
    right: -5px !important;
}

.main-panel {
    background: rgba(203, 203, 210, 0.15);
    position: relative;
    float: right;
    width: calc(100% - 260px);
    height: 100vh;
}


/*Categories List*/

.icon-input-group {
    background-color: var(--primary-color) !important;
    color: #ffffff !important;
}

.search-button-container {
    margin-top: 15px;
    text-align: center;
}

.search-button-container button {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.custom-card-header {
    min-height: 50px;
}

.custom-product-price-varieties {
    display: flex;
    justify-content: space-between;
}

.custom-product-price-varieties p {
    font-weight: bold;
    font-size: 2.5vw;
    white-space: nowrap;
    overflow: hidden;
}

.custom-product-price-varieties span {
    font-weight: bold;
}

.button-send-to-cart {
    width: 65px;
    border-radius: 50% !important;
    height: 65px;
}

.button-send-to-cart svg {
    font-size: 25px !important;
    width: 100% !important;
}

@media screen and (max-width: 1399px) {
    .button-send-to-cart {
        width: 50px;
        height: 50px;
    }
    .button-send-to-cart svg {
        font-size: 25px !important;
        width: 100% !important;
    }
}

@media screen and (max-width: 1199px) {
    .button-send-to-cart {
        width: 65px;
        height: 65px;
    }
}

@media screen and (max-width: 767px) {
    .margin-mobile {
        margin-bottom: 10px;
    }
    .custom-product-price-varieties p {
        font-size: 12vw;
    }
}


/*Floating grocery list*/

.floating-grocery-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 99999;
    width: 80px;
    height: 80px;
    background-color: var(--tertiary-color) !important;
    border-radius: 50% !important;
    box-shadow: 0 0 10px #000000 !important;
    border: none !important;
}

.floating-grocery-list {
    position: fixed;
    right: 50px;
    bottom: 150px;
    width: 300px;
    height: 500px;
    z-index: 99999;
    background-color: #ffffff;
    box-shadow: 0 0 10px #000000 !important;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.floating-grocery-list>.visible {
    display: block;
    -webkit-animation: fadein 2s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s;
    /* Firefox < 16 */
    -ms-animation: fadein 2s;
    /* Internet Explorer */
    -o-animation: fadein 2s;
    /* Opera < 12.1 */
    animation: fadein 2s;
}

.floating-grocery-list>.invisible {
    display: none;
}

.mobile-grocery-list {
    display: none !important;
}


/*Notification*/

.custom-notification {
    background-color: var(--primary-color) !important;
    color: #ffffff;
}


/*Custom form*/

.custom-control-input:checked~.custom-control-label::before {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
}


/*Modal plus*/

.modal-plus-mobile {
    display: none;
}

.modal-plus-desktop {
    display: block;
}

@media screen and (min-width: 767.98px) {
    /*Search bar*/
    .custom-search-box {
        display: flex;
    }
    .custom-search-li {
        width: 100%;
        display: flex !important;
        justify-content: center;
        align-content: center;
    }
    .custom-search-li>.input-group {
        width: 50%;
    }
    .custom-mobile-grocery-list-image {
        display: none !important;
    }
}

.custom-search-li>.input-group .input-group-text {
    cursor: pointer;
    background-color: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color) !important;
    color: #ffffff !important;
}

.image-pages-list {
    width: 100%;
    cursor: pointer;
}

.custom-search-bar-wrapper {
    padding: 3vh;
}


/* Grocery List */

.popover {
    z-index: 1040 !important;
}

.custom-popover-header {
    background-color: var(--primary-color) !important;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
}

.custom-popover-header svg {
    cursor: pointer;
}

.popover-body {
    display: flex;
    flex-direction: column;
}

.custom-scrollbars {
    order: 1;
    height: 50vh !important;
}

.custom-total-price {
    display: flex;
    order: 2;
    background-color: var(--primary-color) !important;
    color: #ffffff;
}

.custom-popover-footer {
    order: 3;
    width: 100%;
    background-color: #ffffff;
}

.custom-grocery-item {
    padding: 15px 15px 15px 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    /*border-radius: 6px;
    margin-bottom: 3vh;*/
    border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
}

.custom-grocery-back-btn {
    padding: 15px 0 15px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background-color: var(--primary-color);
    border-radius: 6px;
    margin-bottom: 3vh;
    cursor: pointer;
}

.custom-grocery-product-info-container {
    min-height: 85%;
}

.custom-grocery-back-btn span {
    color: #ffffff;
}

.custom-container {
    background-color: rgb(250, 250, 250);
}

.my-grocery-card {
    padding-right: 6vh;
    padding-left: 6vh;
}

.custom-my-grocery-img {
    width: 5%;
}

.custom-div-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Custom Modals */

.modal-header {
    background-color: var(--primary-color) !important;
    color: #ffffff;
}

.modal-header>button>span {
    color: #ffffff;
}


/* Input priority */

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 1 !important;
}


/* Animation saltello*/

.verticalListaSpesa,
.vertical {
    animation: vertical_207 5.44s ease infinite;
    transform-origin: 50% 50%;
    animation-delay: -3.94s;
}


/* Animation saltello solo se in customProduct*/

.customProduct .vertical {
    animation: fadeIn 3s infinite alternate;
    transform-origin: 0% 0%;
    animation-delay: 0s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
}

@keyframes vertical_207 {
    0% {
        transform: translate(0, -3px);
    }
    1.47059% {
        transform: translate(0, 3px);
    }
    2.94118% {
        transform: translate(0, -3px);
    }
    4.41176% {
        transform: translate(0, 3px);
    }
    5.88235% {
        transform: translate(0, -3px);
    }
    7.35294% {
        transform: translate(0, 3px);
    }
    8.08824% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}


/* Share */

.custom-share-container {
    margin-left: -5px !important;
}

.custom-share-container * {
    margin-right: 5px;
    cursor: pointer;
}


/*Sidebar*/

.custom-sidebar-header>img {
    width: 100% !important;
}

.slider-list:focus, .slider-slide:focus, .slider-list, .slider-slide, .slider-slide div, .slider-slide div:focus, .slider-frame, .slider{
    outline: none !important;
    border: none;
    outline:none;
}

.headerBack{
    margin-bottom: 15px;
}

.mobilePadding5{
    padding-top: 15px;
    padding-bottom: 15px;
}

.slider-list{
    /*min-height: calc(100vh - 163px);
    min-height: inherit;
    min-height: calc(var(--vh, 1vh) * 100 - 163px); */
}

.slider-list li{
  /*  position: relative !important; 
  height: auto !important;*/
}


#closeIcon{
    display: none;
}

.checkMobileClassImg{
  /*  height: auto !important;
    width: auto !important;
    max-width: 100%;
    max-height: calc(100vh - 100px) !important;*/
} 

.carousel-pagination-button{
    padding: .25rem .75rem !important;
    font-size: 1rem!important;
    line-height: 100%!important;
}

/* Mobile */

@media screen and (max-width: 767.98px) {
    .right-sidebar {
        display: none;
    }

    .width20 li{
        width: 100% !important;
    }

    #closeIcon{
        display: block;
    }

    #closeIcon{
        position: absolute;
        top: 5px;
        right: 10px;
    }

    #prodottoNonPresente{
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .mobilePadding5{
        padding-left: 5px !important;
        padding-right: 5px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .iconTopName{
        color: #009ee0;
        font-size: 11px;
        line-height: 13px;
    }

    .headerBack .mini-drawer-menu-icon{
        height: 48px;
        margin: 0px !important;
    }

    .checkMobileClassImg{
        height: auto !important;
        width: auto !important;
        max-width: 100%;
        max-height: calc(var(--vh, 1vh) * 100 - 113px) !important;
    } 

    .custom-search-li {
        display: none !important;
    }
    .custom-search-bar-wrapper .input-group-text {
        cursor: pointer;
        background-color: var(--secondary-color) !important;
        border: 1px solid var(--secondary-color) !important;
        color: #ffffff !important;
    }
    .headerBack {
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        padding-right: 0px !important;
        padding: 0 !important;
    }

    .headerBack .top-header-icon{
        margin-top: 0px !important;
        width: 29px!important;
        height: 29px!important;
        background: transparent !important;
        box-shadow: none !important;
    }

    #mobile-volantino i{
        color: #009ee0 !important;
    }

    .headerBack .top-header-icon i{
        font-size: 20px !important;
        line-height: 24px;
    }
    .route-height {
        margin-right: 0 !important;
    }
    .mobile-grocery-list {
        display: block !important;
        width: 20%;
        padding-top: 4px;
        height: 48px;
        margin-right: 0 !important;
        text-align: center;
    }

    .mobile-grocery-list div, .mobile-grocery-list button{
        border-radius: 0 !important;
    }

    .headerBack .top-header-icon{
        margin: auto;
    }

    #mobile-search-hidden{
        display: none !important;
    }
}

@media screen and (max-width: 600px) {

    .reduceMobile{
        font-size: 15px;
    }

    .custom-sidebar-header>img {
        width: 80% !important;
    }
    .custom-container {
        background-color: rgb(250, 250, 250);
        padding: 5%;
    }
    .filtered-products .marker {
        display: block !important;
    }
    .marker:not([type="plus"]) {
        /* display: none; */
    }
    /*icone marker prodotto in categorie*/
    @media screen and (max-width: 600px) {
        .marker:not([type="plus"]) .icon-marker {
            margin-right: 5px;
            width: 30px;
        }
    }
    /*icone marker sul prodotto*/
    @media screen and (max-width: 600px) {

        .icon-marker {
            width: 35px;
            margin-right: 15px !important;
            margin-left: 0px !important;
        }

        .markers-container-no-margin .icon-marker{
            margin-right: 5px !important;
            margin-left: 0px !important;
        }

        .customProduct .marker:not([type="plus"]) .icon-marker {
            margin-right: 5px !important;
            width: 22px;
        }
        .marker[type="plus"] .icon-marker {
            margin-right: 5px !important;
            width: 18px;
        }
    }
    .floating-grocery-button {
        display: none !important;
    }
    /*Modal Full Screen*/
    .modal-dialog {
        margin: auto !important;
    }
    .modal-content {
        border: none !important;
        border-radius: inherit !important;
    }
    .custom-popover {
        transform: unset !important;
        margin-top: 0px !important;
        border: unset !important;
        border-radius: unset !important;
        max-width: 100% !important;
        width: 100%;
        height: 100% !important;
    }
    /* Gestione scrollbar in grocery list da mobile */
    @media screen and (max-height: 340px) {
        .custom-scrollbars {
            height: 35vh !important;
        }
    }
    @media screen and (max-height: 430px) and (min-height: 341px) {
        .custom-scrollbars {
            height: 40vh !important;
        }
    }
    @media screen and (max-height: 550px) and (min-height: 431px) {
        .custom-scrollbars {
            height: calc(100vh - 323px) !important;
            height: calc(var(--vh, 1vh) * 100 - 201px)!important
        }
    }
    @media screen and (min-height: 551px) and (max-height: 700px) {
        .custom-scrollbars {
            height: calc(100vh - 323px) !important;
            height: calc(var(--vh, 1vh) * 100 - 161px)!important
        }
    }
    @media screen and (min-height: 701px) {
        .custom-scrollbars {
            height: calc(100vh - 273px) !important;
            height: calc(var(--vh, 1vh) * 100 - 161px)!important
        }
    }
    /* Gestione scrollbar in grocery list da mobile */
    /*Modal plus*/
    .modal-plus-mobile {
        display: block;
    }
    .modal-plus-desktop {
        display: none;
    }
    .custom-grocery-list-carousel {
        display: none !important;
    }
    .custom-grocery-product-info-container {
        min-height: 0px;
    }
    /* Scroll to top */
    .scroll-up-btn {
        bottom: 20px !important;
        right: 20px !important;
        font-size: 2em;
    }
    .icon-marker {
        margin-right: 10px;
    }
}

@media screen and (max-height: 600px) {
    .custom-my-grocery-scrollbar {
        height: 60vh !important;
    }
}

@media screen and (max-height: 700px) and (min-height: 601px) {
    .custom-my-grocery-scrollbar {
        height: 62vh !important;
    }
}

@media screen and (max-height: 800px) and (min-height: 701px) {
    .custom-my-grocery-scrollbar {
        height: 65vh !important;
    }
}

@media screen and (min-height: 801px) {
    .custom-my-grocery-scrollbar {
        height: 70vh !important;
    }
}

@media screen and (min-height: 1001px) {
    .custom-my-grocery-scrollbar {
        height: 80vh !important;
    }
}

@media screen and (max-width: 1500px) {
    .custom-my-grocery-img {
        width: 70px !important;
        height: 70px !important;
    }
}

.scroll-up-btn {
    position: fixed;
    bottom: 50px;
    right: 100px;
    cursor: pointer;
    color: var(--secondary-color);
}


/* ricerca in index */

li.alice-carousel__stage-item.__active {
    width: calc(100% / 8) !important;
}

.alice-carousel__wrapper {
    height: 30vh !important;
}

.search-carousel-close-icon {
    color: var(--secondary-color);
}

.search-carousel-icon {
    cursor: pointer;
}

.search-carousel-icon-wrapper {
    height: calc(30vh - 60%);
}

.search-carousel-icons-container {
    height: 30vh;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1250px) {
    .alice-carousel__wrapper {
        height: 20vh !important;
    }
    .search-carousel-icon-wrapper {
        height: calc(20vh - 60%);
    }
    .search-carousel-icons-container {
        height: 20vh;
        display: flex;
        flex-direction: column;
    }
}


/* ricerca in index */


/** PAGE TITLE STYLE */

.page-title {
    background: var(--primary-color);
    color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px 0px, var(--primary-color) 0px 13px 24px -11px;
}

.page-title-color {
    color: var(--primary-color);
}

.client-logo-header-mygrocerylist {
    width: 80px;
    border-radius: 50%;
}

.app-logo-header-mygrocerylist {
    width: 70px;
    border-radius: 50%;
}

@media screen and (max-width: 600px) {
    .client-logo-header-mygrocerylist {
        width: 50px;
        border-radius: 50%;
    }
    .app-logo-header-mygrocerylist {
        width: 40px;
        border-radius: 50%;
    }
}


/** PAGE TITLE STYLE */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .top-header-icon {
        background: var(--primary-color) !important;
    }
    .icon-marker {
        height: 25px;
    }
}

.change-product-quantity-icon {
    color: var(--primary-color);
}

.related_products_container .alice-carousel__wrapper {
    height: auto !important;
}

.related_products_container {
    overflow-y: hidden;
}

.related_products_container li.alice-carousel__stage-item.__active {
    width: calc(100% / 5) !important;
}


/* hide scrollbar in Chrome and Safari */

#scrollable-div::-webkit-scrollbar {
    width: 0 !important;
}


/* hide scrollbar in Firefox */

#scrollable-div {
    scrollbar-width: none;
}


/* hide scrollbar in IE */

#scrollable-div {
    -ms-overflow-style: none;
}

.custom-page-title {
    font-size: 2vw !important;
}

@media screen and (max-width: 600px) {
    .custom-page-title {
        font-size: 4vw !important;
    }
}

.modal-page-video-container {
    background: #777;
}


@media screen and (max-width: 767px) {
    .modal-page-video-container {
        position: absolute;
        top: 10%;
        left: 0;
        right: 0;
        height: 0;
        padding-bottom: 56.25%;
        height: 75%;
    }
}

@media screen and (min-width: 768px) {
    .modal-page-video-container {
        position: absolute;
        bottom: 0;
        /* left: calc(50% - 500px);
    right: calc(50% - 500px); */
        left: 0;
        right: 0;
        /* height: 0; */
        /* padding-bottom: 56.25%; */
        padding-bottom: 33.75%;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
    }
}

.react-page-player {
    position: absolute;
    top: 0;
    left: 0;
}

.close-page-video-button {
    cursor: pointer;
    background: #000;
    opacity: 0.5;
    color: #fff;
    border: 0;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

@media screen and (max-width: 767px) {
    .close-page-video-button {
        font-size: 14px;
    }
}

.audio-page-video-button {
    cursor: pointer;
    background: #000;
    opacity: 0.5;
    color: #fff;
    border: 0;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

.audio-page-video-button-bottom-left {
    cursor: pointer;
    background: #000;
    opacity: 0.5;
    color: #fff;
    border: 0;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
}

.audio-page-video-button-top-left {
    cursor: pointer;
    background: #000;
    opacity: 0.5;
    color: #fff;
    border: 0;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

@media screen and (max-width: 767px) {
    .audio-page-video-button {
        font-size: 14px;
    }
    .audio-page-video-button span {
        display: none;
    }

    .audio-page-video-button-top-left {
        font-size: 14px;
    }

    .audio-page-video-button-bottom-left {
        font-size: 14px;
    }
    .audio-page-video-button-bottom-left span {
        display: none;
    }
}

.wait-page-video-button {
    background: #000;
    opacity: 0.5;
    color: #fff;
    border: 0;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

@media screen and (max-width: 767px) {
    .wait-page-video-button {
        width: 100%;
        border: 0;
        padding: 5px 20px;
        font-size: 14px;
        right: 0;
        left: 0;
        bottom: 0;
        top: auto;
        z-index: 2;
        text-align: center;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .onlyShopping {
        height: 70px;
        font-size: 15px;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1700px) {
    .onlyShopping {
        height: 45px;
        font-size: 15px;
    }
}

/*Sottotitolo Volantino Interattivo*/

.subtitle-mobile {
    font-size: 14px;
    margin-left: 33px;
    margin-top: 5px;
}

.subtitle-desktop {
    color: var(--primary-color) !important;
    font-size: 18px;
    margin-left: 0px;
    margin-top: 0px;
}